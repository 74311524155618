import { useState, useEffect } from 'react'
import { BREAKPOINTS } from '../constants/breakpoints'

type useResponsiveTypes = {
    threshold?: number
    onMobile?: () => void
    onDesktop?: () => void
}

/**
 * A responsive hook that detects viewport size changes based on a threshold width.
 *
 * @param {Object} options - Configuration options
 * @param {number} [options.threshold=BREAKPOINTS.md] - Breakpoint width in pixels
 * @param {() => void} [options.onMobile] - Callback function triggered when viewport width becomes smaller than threshold
 * @param {() => void} [options.onDesktop] - Callback function triggered when viewport width becomes larger than threshold
 *
 * @returns {boolean} Returns true if viewport width is below threshold, false otherwise
 *
 * @example
 * const isMobile = useResponsive({
 *   threshold: 768,
 *   onMobile: () => console.log('Mobile view'),
 *   onDesktop: () => console.log('Desktop view')
 * });
 */
const useResponsive = ({
    threshold = BREAKPOINTS.md,
    onMobile,
    onDesktop,
}: useResponsiveTypes = {}): boolean => {
    const [isBelowThreshold, setIsBelowThreshold] = useState(() => {
        if (typeof window !== 'undefined') {
            return window.matchMedia(`(max-width: ${threshold}px)`).matches
        }
        return false
    })

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const mediaQuery = window.matchMedia(`(max-width: ${threshold}px)`)
            setIsBelowThreshold(mediaQuery.matches)

            const handleResize = () => {
                if (typeof window === 'undefined') return

                const mediaQuery = window.matchMedia(`(max-width: ${threshold}px)`)
                if (mediaQuery.matches) {
                    setIsBelowThreshold(true)
                    if (onMobile) onMobile()
                } else {
                    setIsBelowThreshold(false)
                    if (onDesktop) onDesktop()
                }
            }

            mediaQuery.addEventListener('change', handleResize)

            return () => {
                mediaQuery.removeEventListener('change', handleResize)
            }
        }
    }, [threshold, onMobile, onDesktop])

    return isBelowThreshold
}

export default useResponsive
